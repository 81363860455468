


























































































































import { defineComponent, ref, computed, reactive, Ref } from '@vue/composition-api';
import { useFavorites } from '@/composition/favorites';
import { useNotification } from '@/composition/notification';
import { savePermission, getPermissions, Permission } from '@/admin/permission';
import FcAuthority from '@/components/FcAuthority.vue';
import { DisplayDate } from '@/admin/util';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';

interface Authority {
  planIds: string[];
  seasonIds: number[];
  productIds: string[];
  giftIds: string[];
  acceptAllUsers: boolean;
  acceptAllMembers: boolean;
  deny: boolean;
}
export default defineComponent({
  name: 'Favorites',
  components: {
    FcAuthority,
    FcRoleLoading,
    FcRoleDeny,
    FcStaticImage,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('favorites'));

    const notification = useNotification();
    const { favorites } = useFavorites();
    const headers = [
      { text: 'ステータス', value: 'status' },
      { text: 'デフォルト画像', value: 'defaultImage' },
      { text: '名前', value: 'favoriteItemName' },
      { text: '選択可能期間', value: 'accessDate' },
      { text: '表示終了日', value: 'displayEndDate' },
      { text: '期間限定画像', value: 'limitedDisplaySettings' },
    ];
    const displayFavorites = computed(() => favorites.items);
    const now = DisplayDate.now();

    // テーブル高さ
    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    // 権限の設定
    const active = ref(false);
    const isSavePermission = ref(false);
    const isFetchPermissions = ref(true);
    const favoriteItemPermission: Ref<Permission | null> = ref(null);
    const permissionAuthority: Authority = reactive({
      planIds: [],
      seasonIds: [],
      productIds: [],
      giftIds: [],
      acceptAllUsers: false,
      acceptAllMembers: false,
      deny: false,
    });
    getPermissions()
      .then((permissions) => {
        const permission = permissions.find((item) => item.type === 'favoriteItem');
        if (permission) favoriteItemPermission.value = permission;
        isFetchPermissions.value = false;
      })
      .catch((error) => {
        notification.error(error);
        isFetchPermissions.value = false;
      });
    const cancel = () => {
      active.value = false;
    };
    const open = () => {
      active.value = true;
      if (favoriteItemPermission.value) {
        permissionAuthority.planIds = favoriteItemPermission.value.planIds || [];
        permissionAuthority.seasonIds = favoriteItemPermission.value.seasonIds || [];
        permissionAuthority.productIds = favoriteItemPermission.value.productIds || [];
        permissionAuthority.giftIds = favoriteItemPermission.value.giftIds || [];
        permissionAuthority.acceptAllUsers = favoriteItemPermission.value.acceptAllUsers || false;
        permissionAuthority.acceptAllMembers = favoriteItemPermission.value.acceptAllMembers || false;
        permissionAuthority.deny = favoriteItemPermission.value.deny || false;
      }
    };
    const save = async () => {
      if (
        !permissionAuthority.acceptAllMembers &&
        !permissionAuthority.deny &&
        !permissionAuthority.planIds.length &&
        !permissionAuthority.seasonIds.length
      ) {
        notification.error('権限を設定してください');
        return;
      }
      const props = {
        type: 'favoriteItem',
        path: 'favoriteItem',
        ...permissionAuthority,
      };
      const method = favoriteItemPermission.value ? 'PUT' : 'POST';
      isSavePermission.value = true;
      try {
        await savePermission(props, method);
        favoriteItemPermission.value = props;
        notification.notify('保存しました');
      } catch (error) {
        notification.error(error);
      }
      isSavePermission.value = false;
      cancel();
    };

    return {
      DisplayDate,
      pageTitle: '推しメン設定',
      myRoleSettings,
      headers,
      displayFavorites,
      tableHeight,
      favorites,
      cancel,
      open,
      save,
      active,
      isSavePermission,
      isFetchPermissions,
      permissionAuthority,
      now,
    };
  },
});
